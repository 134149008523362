import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from '../../conditional-render';

class Submit extends React.Component {
  render() {
    const { onClick, disabled, className, value, loading } = this.props;
    const classString = `sl-new-login-button ${className || ''}`;
    return (
      <div className="sl-new-login-button-wrapper">
        <button
          type="submit"
          onClick={onClick}
          role="button"
          disabled={disabled || loading}
          className={classString}
        >
          {value}
          <ConditionalRender if={loading}>
            <div className="sl-new-login-button__spinner_container">
              <div className="sl-new-login-button__spinner">
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
            </div>
          </ConditionalRender>
        </button>
      </div>
    );
  }
}

Submit.propTypes = {
  type: PropTypes.any,
  value: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Submit;
